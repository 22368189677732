@import '@infopulse-design-system/shared/theme/colors.scss';
@import '@infopulse-design-system/shared/mixins/media.scss';

.footnote-table {
  .MuiIconButton-root {
    border: none;
  }

  .MuiFormControl-root {
    margin: 0;
    width: 100%;
  }

  .edit td {
    padding: 1px;

    &:first-of-type {
      padding-left: 16px;
    }
  }

  .disabled {
    pointer-events: none;
    opacity: 0.7;
    td {
      color: $error_main;
    }
  }

  textarea {
    line-height: var(--body2_line_height);
  }
}
