$primary_light: #f5f4ff;
$primary_main: #06037d;
$primary_dark: #06037d;

$secondary_light: #9c9990;
$secondary_main: #16697A;
$secondary_dark: #8a4f7d;

$grey_500: #adb5bd;
$grey_600: #8c8c8c;
$grey_700: #686868;
$grey_800: #435058;
$grey_900: #495057;

$error_light: rgba(214, 40, 40, 0.5);
$error_main: #d62828;
$error_dark: #ad3131;

$success_light: rgba(14, 173, 105, 0.5);
$success_main: #0ead69;
$success_dark: #26895f;

$warning_light: rgba(249, 174, 61, 0.5);
$warning_main: #f9ae3d;
$warning_dark: #ae7b2e;

$info_light: rgba(142, 140, 203, 0.5);
$info_main: #8e8ccb;
$info_dark: #353451;

$background: #ffffff;

$text_primary: #000000;
$text_secondary: #ffffff;

:export {
  primary_light: $primary_light;
  primary_main: $primary_main;
  primary_dark: $primary_dark;
  secondary_light: $secondary_light;
  secondary_main: $secondary_main;
  secondary_dark: $secondary_dark;
  grey_500: $grey_500;
  grey_600: $grey_600;
  grey_700: $grey_700;
  grey_800: $grey_800;
  grey_900: $grey_900;
  error_light: $error_light;
  error_main: $error_main;
  error_dark: $error_dark;
  success_light: $success_light;
  success_main: $success_main;
  success_dark: $success_dark;
  warning_light: $warning_light;
  warning_main: $warning_main;
  warning_dark: $warning_dark;
  info_light: $info_light;
  info_main: $info_main;
  info_dark: $info_dark;
  background: $background;
  text_primary: $text_primary;
  text_secondary: $text_secondary;
}
