@import './reset.scss';
@import '../../theme/scrollbar.scss';
@import '@infopulse-design-system/shared/mixins/index.scss';

body {
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

#root {
  position: fixed;
  width: 100%;
}

main {
  box-shadow: inset 21px 0 21px rgba(162, 159, 159, 0.25);
}

header + main {
  .basket,
  .person-library,
  .media-processing,
  .admin-panel {
    height: calc(100vh - 70px);
  }
  .main {
    height: calc(100vh - 90px);
  }
  .details-preview,
  .persona-wrapper {
    padding: 15px 15px 0 15px;
    position: relative;
    height: calc(100vh - 85px);
  }
}

.main,
.basket,
.person-library {
  display: flex;
  position: relative;

  // todo: removed bottom shadow to avoid not clickable zone
  // &:after {
  //   content: '';
  //   position: absolute;
  //   bottom: 0;
  //   height: 50px;
  //   width: 100%;
  //   background: linear-gradient(to bottom, transparent, white);
  // }

  &-filter,
  &-result,
  &-preview {
    padding: 25px 12px 0 12px;
    box-sizing: border-box;
  }

  &-filter,
  &-result {
    border-right: 1px solid #e6e5ff;
  }

  #InfiniteScroll {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 50.5px); // 50.5px is panel title
  }

  &-filter {
    width: 280px;
    min-width: 280px;
    height: 100%;
    padding-right: 0;
    padding-left: 0;
    .main-subtitle {
      padding-left: 12px;
    }
  }

  &-preview {
    flex: 1;
  }

  @include tablet-landscape-down {
    &-result {
      border-right: none;
      width: 100%;
    }
  }

  @include tablet-portrait-down {
    .filters-btn {
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 10;
    }
    &-preview {
      width: 100%;
    }
  }

  @include mobile-only {
    &-result {
      min-width: 300px;
      .search-result-item {
        height: 175px;
      }
    }
  }
}

.details-preview,
.main-result,
.basket-result,
.person-library-result,
.persona-info-popup > .MuiPopover-paper,
.persona-edit-popup > .MuiPopover-paper,
.speaker-select-menu > .MuiPopover-paper,
.media-processing,
.media-processing .media-list,
.persona-wrapper {
  @include progress-bar;
}

.btn_go-back {
  margin-bottom: 10px;

  &.btn_go-back.btn_go-back {
    text-decoration: none;
  }
  .button-content {
    display: flex;
    align-items: center;
    padding: 5px;
    svg {
      margin-right: 7px;
    }
  }
}

a.disabled {
  pointer-events: none;
}

.info-message {
  margin-top: 20px;
  text-align: center;
}

.bold {
  font-weight: 700;
}

.semi-bold {
  font-weight: 600;
}

.medium {
  font-weight: 500;
}

.normal {
  font-weight: 400;
}

.underline {
  text-decoration: underline;
}

.primary-main {
  color: $primary_main;
}

.fs14 {
  font-size: 14px;
}

.fs10 {
  font-size: 10px;
}

.mb10 {
  margin-bottom: 10px;
}

.ml6 {
  margin-left: 6px;
}

.mb6 {
  margin-bottom: 6px;
}

.pr-i-circle-animation {
  position: relative;
  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid $primary_main;
    border-bottom-color: transparent;
    transform: rotate(0deg);
    animation: rotate 1s linear infinite;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
