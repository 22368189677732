$xs: 0;
$sm: 600px;
$md: 800px;
$lg: 1200px;
$xl: 1440px;

:export {
  xs: $xs;
  sm: $sm;
  md: $md;
  lg: $lg;
  xl: $xl;
}
