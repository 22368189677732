@import '@infopulse-design-system/shared/theme/colors.scss';
@import '@infopulse-design-system/shared/theme/shadows.scss';

.transcription_bug {
  width: calc(100% - 25px);
  word-break: break-all;
  hyphens: auto;
  position: relative;

  .edit-icon {
    position: absolute;
    top: 0;
    right: -20px;
  }
}

.incorrect-transcription-text {
  .MuiPaper-root {
    max-width: 500px;
    padding: 8px;
    background: $background;
    border-radius: 10px;
    color: $error_main;
    font-weight: 400;
    font-size: 14px;
    box-shadow: $tooltip_box_shadow;
    margin-bottom: 0;
  }
}
