@import '@infopulse-design-system/shared/theme/colors.scss';

.transcription {
  height: 62px;
  overflow: hidden;
  display: -webkit-box;
  word-break: break-all;
  hyphens: auto;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  &_details {
    width: calc(100% - 25px);
    position: relative;
    word-break: break-all;
    hyphens: auto;

    .report-icon {
      position: absolute;
      top: 0;
      right: -20px;
    }
  }

  &_time {
    font-weight: 700;
  }

  &_persona,
  &_speaker {
    font-weight: 600;
    .similar-embeddings-number {
      font-size: 0.75em;
    }
  }

  &_speaker {
    display: inline-block;
    margin: 0 8px;
    color: $primary_main;

    + .MuiButtonBase-root {
      margin-left: -8px;
    }
  }

  &_active {
    color: $primary_main;
  }
}
