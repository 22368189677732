@import '@infopulse-design-system/shared/theme/colors.scss';
@import '@infopulse-design-system/shared/mixins/media.scss';

.search-words {
  margin: 16px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  min-height: 26px;

  &-add-btn.MuiChip-root {
    padding: 0;
    max-width: 33px;
    min-width: 33px;
    .MuiChip-label {
      font-size: 24px;
      line-height: 1.2;
    }
  }

  &-chip {
    padding: 0 8px;
    span {
      font-weight: 600;
    }
  }

  .MuiFormControl-root {
    margin: 0;
    height: 26px;
  }
}
