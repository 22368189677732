// @import '@infopulse-design-system/shared/theme/typography.scss';
// @import '@infopulse-design-system/shared/theme/colors.scss';
@import '@infopulse-design-system/shared/mixins/index.scss';

.media-processing {
  .form-wrapper {
    margin: 0 auto;
    margin-top: -60px;
    padding-top: 20px;
    height: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;
    @media (max-width: 500px) {
      margin-top: -90px;
    }
  }

  .media-list {
    position: relative;
    margin-top: 8px;
    width: 450px;
    min-height: 50vh;
  }

  .error-info-icon {
    position: relative;
    top: -1px;
    margin: 0 2px;
  }
}
