@import '@infopulse-design-system/shared/theme/colors.scss';
@import '@infopulse-design-system/shared/mixins/media.scss';

.language-switcher {
  display: flex;
  align-items: baseline;
  margin: 0 5px;

  .ngo-select {
    margin: 0;
  }

  .IpSelect-select--medium {
    min-width: 30px;

    &:after,
    &:before {
      display: none;
    }
  }

  .MuiSelect-select.MuiSelect-select {
    padding: 0 30px 0 5px !important;
    font-weight: 600;
    color: $primary_main;
  }

  @include mobile-only {
    .ngo-text {
      display: none;
    }
  }
}
