@import '@infopulse-design-system/shared/theme/colors.scss';
@import '@infopulse-design-system/shared/mixins/index.scss';

.person-result-item {
  .top-line {
    align-items: center;
  }

  .persona-info {
    margin: 12px 0;
    ul {
      max-width: calc(100% - 90px);
    }
    li {
      @include text-ellipsis;
    }
  }
}
