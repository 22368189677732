@import '@infopulse-design-system/shared/theme/colors.scss';
@import '@infopulse-design-system/shared/mixins/media.scss';

.persona-edit-popup {
  .MuiPopover-paper {
    overflow: hidden;
    padding: 32px 0 16px 0;
  }

  &_content {
    box-sizing: border-box;
    padding: 0 20px 16px 20px;
    margin-right: 6px;
    max-width: calc(100vw - 32px);
    max-height: calc(100vh - 80px);
    width: 700px;
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  h3 {
    margin-bottom: 6px;
    display: inline-block;
  }

  h4 {
    margin: 10px 0;
  }

  .IpForm-element-error {
    position: absolute;
    font-size: 10px;
    line-height: 13px;
    margin: 0;
  }

  .ngo-input {
    margin-top: 15px;
  }

  .persona-form {
    &_photo-selection {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &_elements {
      position: relative;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 5px 0;

      & > div {
        min-width: 48%;
        max-width: 48%;

        @include mobile-only {
          min-width: 100%;
          max-width: 100%;
        }
      }
    }

    &_input-wrapper {
      margin-bottom: 12px;
    }

    &_submit--wrapper {
      display: flex;
      justify-content: flex-end;

      button:first-of-type {
        margin-right: 6px;
      }
    }

    label {
      top: 0;
      left: 0;
    }
  }

  .file-input-wrapper {
    label,
    label.Mui-focused {
      top: -8px;
      transform: none;
    }

    input {
      height: 35px;
    }
  }

  .photo-url_input {
    min-width: 300px;
  }

  .ngo-select {
    .ngo-menu-item {
      max-width: 314px;
    }
  }
}
