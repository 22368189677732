.sort-menu-btn svg {
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
}

.sort-menu-btn + .MuiMenu-root li {
  padding: 4px 8px;
  min-height: 0;

  p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    width: 100%;
    button {
      margin: 0 3px;
    }
  }
}
