@import '@infopulse-design-system/shared/theme/colors.scss';

.transcription-menu {
  .MuiPaper-root {
    width: 275px;
    padding: 15px;
    box-sizing: border-box;
  }

  .cancel-btn {
    margin-right: 20px;
  }

  &_update-text,
  &_reporting,
  &_narratives {
    .MuiPaper-root.MuiPaper-root {
      width: 700px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    .ngo-text-field {
      margin: 0 0 5px 0;
      width: 100%;
    }
  }

  &_reporting,
  &_narratives {
    .MuiPaper-root.MuiPaper-root {
      justify-content: flex-start;
    }
  }

  .ngo-list-item-subheader {
    padding: 0;
  }

  .ngo-list-item {
    padding: 8px;
    background-color: unset;

    .MuiTypography-root {
      font-weight: 400;
      color: $primary_main;
    }

    &:hover {
      .MuiTypography-root {
        font-weight: 600;
      }
    }

    a {
      display: block;
      width: 100%;
    }
  }
}

.sign-of-crime-dropdown {
  &.sign-of-crime-dropdown {
    max-height: 160px;
  }
  label > .MuiTypography-root {
    font-size: 13px;
  }
}

.sign-of-crime-select-list,
.narrative-list {
  .select-list-chip {
    max-width: 150px;
  }
}

.narrative {
  &-popover,
  &-popover-sub {
    .MuiPaper-root {
      max-width: 260px;
      max-height: fit-content;
      overflow: visible;
    }
  }

  &-item {
    padding: 6px 12px;
    display: flex;
    justify-content: space-between;

    .ngo-icon-button {
      transform: rotate(0deg);
    }

    .open {
      transform: rotate(-90deg);
    }
  }

  &-sub-item {
    padding: 6px;
  }

  &-item,
  &-sub-item .MuiTypography-root {
    color: $primary_dark;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &-list {
    .ngo-chip {
      max-width: 160px;
    }
  }
}
