@import '@infopulse-design-system/shared/mixins/media.scss';

.header {
  height: 70px;
  padding: 0 20px;
  display: flex;
  align-items: center;

  &_main-page {
    height: 90px;
    .header_right-side-wrapper {
      height: 55px;

      @media (max-width: 450px) {
        position: relative;
        top: 20px;
      }
    }
  }

  &__main-content {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 260px);
    margin-left: 45px;

    @media (max-width: 450px) {
      margin-left: 24px;
    }

    &-top-line {
      display: flex;
      align-items: center;
    }
  }

  &-logo {
    margin-left: 6px;
  }

  &-search {
    margin: 0 10px 0 0;
    width: 400px;
    min-width: 100px;

    .MuiFormHelperText-root.Mui-error {
      position: absolute;
      top: -16px;
      left: 16px;
    }

    @include mobile-only {
      width: 100%;
    }
  }

  .filters-reset {
    padding: 12px 0;
    margin-right: 10px;
    height: 35px;
  }

  .MuiChip-label {
    font-size: 13px;
  }

  &_right-side-wrapper {
    display: flex;
    align-items: center;
    height: 40px;
    margin-left: auto;
    border-left: 2px solid #d9d9d9;
    padding-left: 13px;
  }

  @media (max-width: 1024px) {
    &_right-side-wrapper {
      border-left: none;
    }
  }

  @include mobile-only {
    padding: 0 15px;
  }
}
