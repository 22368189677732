.filter {
  &-tags {
    display: flex;
    overflow-x: scroll;
    margin-right: auto;
    position: relative;
    padding-bottom: 3px;
    top: 5px;

    &-wrapper {
      display: flex;
    }
  }

  &-tag.ngo-chip {
    margin-right: 11px;
    padding: 0 5px;
    font-size: 13px;
    line-height: 13px;
  }
}
