@import '../../../constants/global.config.constants';

.#{$prfx}-form {
  &--react {
  }

  .#{$prfx}-select {
    label {
      transform: translate(0, -9px) scale(0.75);
      left: 0;
    }
    .MuiSelect-select {
      padding-left: 0;
      padding-right: 25px !important;
    }

    .MuiMenu-paper {
      padding: 0;
    }

    .#{$prfx}-menu-item {
      padding: 5px 10px;
      min-height: 30px;
      font-size: 14px;
      line-height: 16px;
      max-width: 300px;
      text-wrap: wrap;
    }
  }
}
