$table_max_height_mobile: 80vh;
$table_chip_margin: 12px 16px 0 0;
$table_pin_icon_size: 12px;
$table_pin_icon_top: 5px;
$table_pin_icon_right: 5px;
$table_top_toolbar_padding: 24px 0 18px 0;
$table_search_width: 100%;
$table_search_margin: 0;
$table_filter_open_btn_width: 40px;
$table_filter_open_btn_margin: 0 0 0 20px;
$table_filter_reset_btn_margin: 12px 16px 0 0;
$table_filter_range_width: 95%;
$table_filter_text_min_width: 200px;
$table_filter_range_input_width: 75px;
$table_filter_range_input_width_mobile: 123px;
$table_filter_dot_size: 7px;
$table_filter_dot_position_top: 8px;
$table_filter_dot_position_right: 10px;
$table_filter_btns_container_max_width: 328px;
$table_filter_btns_container_padding: 25px;
$table_filter_reset_btn_container_padding_bottom: 25px;
$table_pagination_select_width: 50px;
$table_pagination_text_margin: 0 15px;
$table_pagination_wrapper_margin: 0 20px;

:export {
  table_max_height_mobile: $table_max_height_mobile;
  table_chip_margin: $table_chip_margin;
  table_pin_icon_size: $table_pin_icon_size;
  table_pin_icon_top: $table_pin_icon_top;
  table_pin_icon_right: $table_pin_icon_right;
  table_top_toolbar_padding: $table_top_toolbar_padding;
  table_search_width: $table_search_width;
  table_search_margin: $table_search_margin;
  table_filter_open_btn_width: $table_filter_open_btn_width;
  table_filter_open_btn_margin: $table_filter_open_btn_margin;
  table_filter_reset_btn_margin: $table_filter_reset_btn_margin;
  table_filter_range_width: $table_filter_range_width;
  table_filter_text_min_width: $table_filter_text_min_width;
  table_filter_range_input_width: $table_filter_range_input_width;
  table_filter_range_input_width_mobile: $table_filter_range_input_width_mobile;
  table_filter_dot_size: $table_filter_dot_size;
  table_filter_dot_position_top: $table_filter_dot_position_top;
  table_filter_dot_position_right: $table_filter_dot_position_right;
  table_filter_btns_container_max_width: $table_filter_btns_container_max_width;
  table_filter_btns_container_padding: $table_filter_btns_container_padding;
  table_filter_reset_btn_container_padding_bottom: $table_filter_reset_btn_container_padding_bottom;
  table_pagination_select_width: $table_pagination_select_width;
  table_pagination_text_margin: $table_pagination_text_margin;
  table_pagination_wrapper_margin: $table_pagination_wrapper_margin;
}