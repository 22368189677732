@import '@infopulse-design-system/shared/mixins/media.scss';

.persona-wrapper {
  position: relative;

  h6 {
    margin: 5px 0;
  }

  .persona-preview {
    margin-top: 0;
  }

  @include tablet-landscape-up {
    .persona-info {
      &_avatar {
        margin-right: 40px;
      }

      &_sanctions {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

    .persona-media {
      display: flex;
      align-items: center;

      &_title {
        margin-bottom: 0;
      }
    }
  }
}
