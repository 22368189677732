@import '../../../constants/global.config.constants';
@import '../../colors.scss';

.#{$prfx}-text-field {
  &--react {
    // common styles
    margin-top: 20px;

    & > .MuiInputBase-root {
      padding-right: 10px;
    }

    & > label {
      transform: none;
      top: -20px;
      left: 20px;

      &.MuiFormLabel-root.MuiInputLabel-root {
        line-height: 1;
        &:not(.Mui-error) {
          color: $primary_main;
        }
      }
    }

    legend {
      max-width: 0.01px;
    }

    // DS todo: need to set Ip classes like IpFileInput then we set input type

    // custom classes
    &.file-input {
      .MuiFormLabel-root,
      .MuiFormLabel-root.Mui-focused {
        transform: none;
        left: 0;
      }

      .MuiInputBase-root {
        padding: 0;
      }

      .MuiInputBase-input {
        height: auto;
      }
    }
  }
}
