@import '../theme/breakpoints.scss';

/** prefix dimension
* only - includes only diapason
* up - includes diapason + bigger screens
* down - includes diapason + smaller screens
*/

@mixin mobile-only {
    @media (max-width: $sm) {
        @content;
    }
}

@mixin mobile-up {
    @media (min-width: 0) {
        @content;
    }
}

@mixin tablet-portrait-down {
    @media (max-width: $md) {
        @content;
    }
}

@mixin tablet-portrait-only {
    @media (min-width: $sm) and (max-width: $md) {
        @content;
    }
}

@mixin tablet-portrait-up {
    @media (min-width: $sm) {
        @content;
    }
}

@mixin tablet-landscape-down {
    @media (max-width: $lg) {
        @content;
    }
}

@mixin tablet-landscape-only {
    @media (min-width: $md) and (max-width: $lg) {
        @content;
    }
}

@mixin tablet-landscape-up {
    @media (min-width: $md) {
        @content;
    }
}

@mixin desktop-down {
    @media (max-width: $xl) {
        @content;
    }
}

@mixin desktop-only {
    @media (min-width: $lg) and (max-width: $xl) {
        @content;
    }
}

@mixin desktop-up {
    @media (min-width: $lg) {
        @content;
    }
}

@mixin big-desktop-only {
    @media (min-width: $xl) {
        @content;
    }
}
