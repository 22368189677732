@import '../../../constants/global.config.constants';
@import './index.scss';

.#{$prfx}-date-time-picker {
  &--react {
    &.data-picker {
      width: $date_time_picker_input_min_width;
      margin: 0;
    }
    
    & > .MuiInputBase-root {
      padding: 0 16px;

      & > .MuiInputAdornment-root {
        margin-left: 0;
      }
    }
  }
}

.#{$prfx}-date-time-picker--input {
  &--react {
  }
}
