.preview_addition-menu {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;

  & > span {
    margin: 0 0 6px 6px;
  }
}
