@import '@infopulse-design-system/shared/theme/colors.scss';

.dashboard-menu {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &-wrapper {
    display: flex;
    align-items: flex-end;
  }

  p {
    margin: 0 8px 0 16px;
  }

  .switch,
  .menu-select {
    display: flex;
    margin-right: 26px;
  }

  .switch {
    align-items: center;
    .ngo-text {
      margin: 0 10px;
    }
  }

  .switch-active {
    font-weight: 600;
  }

  .menu-select {
    align-items: baseline;
    .ngo-text {
      font-weight: 600;
      margin: 0 8px 0 0;
    }

    .ngo-select {
      margin: 0;
    }

    .MuiSelect-select.MuiSelect-select {
      font-weight: 400;
      color: $primary_main;
      height: 26px;
    }
  }
}

.cart-type-btn.active {
  border-radius: 4px;
  background-color: $primary_light;
}
