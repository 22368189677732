@import '@infopulse-design-system/shared/theme/colors.scss';
@import '@infopulse-design-system/shared/theme/components/IpTable/styles.scss';
@import '@infopulse-design-system/shared/theme/components/IpChip/index.scss';

/* Styles for MaterialReactTable wrapper. (We created this file because MaterialReactTable can't get className prop)*/
.MuiTableCell-root {
  &.pinned-column {
    &::after {
      background-image: url('./../../../../shared/assets/images/pinned-icon.png');
    }
  }
}

@mixin tableColor($color) {
  .MuiTable-root {
    .MuiTableCell-head {
      background-color: lighten($color, 40%);
    }

    .Mui-TableHeadCell-Content {
      > .MuiBox-root {
        > .MuiDivider-root {
          background: $color;
          width: 5px;
          height: 24px;
          margin: 0;
          border: 0;
        }
      }
    }

    .MuiTableRow-root {
      transition: none;

      &:nth-child(even) td {
        background-color: lighten($color, 45%);
      }

      &:hover td {
        background-color: lighten($color, 35%);
      }
    }

    .MuiTableCell-root {
      border-bottom: 1px solid lighten($color, 30%);
    }
  }
}

.IpTable-top-toolbar {
  display: block;
  padding: $table_top_toolbar_padding;

  &-header {
    display: flex;
  }

  .IpTableChipPanel {
    .MuiChip-root {
      margin: $table_chip_margin;
    }
  }

  .IpTable-search {
    width: $table_search_width;
    margin: $table_search_margin;
  }
}

.IpTable-color-primary {
  @include tableColor($primary_main);
}

.IpTable-color-secondary {
  @include tableColor($secondary_main);
}

.IpTable-color-error {
  @include tableColor($error_main);
}

.IpTable-color-success {
  @include tableColor($success_main);
}

.IpTable-color-warning {
  @include tableColor($warning_main);
}

.MuiDrawer-root .MuiPaper-root {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;

  .IpTableFilter-range-input {
    width: $table_filter_range_input_width_mobile;
  }
}

.mobile {
  .IpTable-top-toolbar {
    .MuiButtonBase-root {
      padding: 0;
    }

    .MuiButton-endIcon {
      margin: 0;
    }
  }
}

.filter-control-btns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: $table_filter_btns_container_max_width;
  padding: $table_filter_btns_container_padding;
  box-sizing: border-box;

  .reset-btn-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: $table_filter_reset_btn_container_padding_bottom;
  }
}

.MuiTablePagination-root {
  .MuiTablePagination-selectLabel,
  .MuiInputBase-root,
  .MuiTablePagination-displayedRows {
    display: none;
  }
}

.IpTable-pagination-wrapper {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin: $table_pagination_wrapper_margin;

  .IpTable-pagination {
    &-text.MuiTypography-root {
      color: $primary_dark;
      margin: $table_pagination_text_margin;
    }

    &-select {
      margin-top: 0;

      .MuiInputBase-root {
        display: block;
        min-width: $table_pagination_select_width;
      }
    }
  }
}
