@import '@infopulse-design-system/shared/theme/colors.scss';

.persona-info-popup {
  .MuiPopover-paper {
    box-sizing: border-box;
    padding: 24px 24px 24px 10px;
    border: 1px solid $primary_dark;
    border-radius: 10px;
    width: 600px;
    min-height: 170px;
  }

  .persona-info {
    &_avatar {
      margin-bottom: 16px;
    }
    ul {
      max-width: calc(100% - 97px);
    }
    li {
      max-width: 100%;
    }
  }

  .persona-media a {
    margin-right: 14px;
  }

  &_close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 100;
  }
}

.persona-info-icon {
  color: #06037d78;
  margin: 0 6px 0 3px;
}
