// @import '@infopulse-design-system/shared/theme/typography.scss';
// @import '@infopulse-design-system/shared/theme/colors.scss';
@import '@infopulse-design-system/shared/mixins/media.scss';

.person-library {
  &-result {
    position: relative;
    min-width: 500px;
    max-width: 600px;

    @include tablet-landscape-down {
      max-width: 100%;
    }
  }

  &-preview {
    height: 100%;
  }

  h2 > span > button {
    margin-left: 10px;
  }
  .person-library-header {
    display: flex;
    justify-content: space-between;
    button {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 400;
      margin-right: 20px;
      border-radius: 2px 2px 0 0;
    }
  }
}
