@import '@infopulse-design-system/shared/theme/colors.scss';
@import '../../../constants/global.config.constants';
@import '../../../../shared/mixins/media.scss';
@import './index.scss';

.#{$prfx}-table {
  &--react {
    .MuiPaper-root {
      box-shadow: none;
    }
    .Mui-TableHeadCell-Content {
      font-size: 18px;
      font-weight: 400;
      color: $primary_main;
    }
    .MuiTable-root.MuiTable-root {
      .MuiTableCell-head {
        background-color: $primary_light;
      }
      .MuiTableRow-root {
        &:nth-child(even) td {
          background-color: $primary_light;
        }

        &:hover td {
          background-color: $primary_light;
        }
      }
      .MuiTableCell-root {
        border: none;
      }
    }
    .IpTable-top-toolbar {
      position: relative;
      display: flex;
      align-items: flex-start;

      .IpTableChipPanel {
        overflow-x: auto;
        overflow-y: hidden;

        &-scrollable {
          display: inline-flex;
          max-width: 1000%;
          padding-bottom: 5px;
        }

        .ngo-chip {
          margin: 0 15px 0 0;
          padding-left: 10px;
        }
      }
      .IpTableFilter-reset-btn {
        margin: 0 15px;
      }
      .IpTableFilter-open-btn {
        margin: 0;
        min-width: 133px;
      }
    }
    .MuiToolbar-root > .MuiBox-root > .MuiBox-root {
      width: 100%;
    }
    .MuiTablePagination-root {
      width: 100%;
    }
    .MuiTablePagination-spacer {
      display: none;
    }
    .MuiToolbar-root {
      padding: 0;
    }
    .IpTable-pagination-wrapper {
      position: relative;
      width: 100%;
      margin: 0;
      z-index: 10;

      .ngo-pagination {
        @include desktop-up {
          position: absolute;
          top: 47.5%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .MuiChip-label {
      font-weight: normal;
      line-height: 35px;
      b {
        font-weight: bold;
      }
    }
  }
}
