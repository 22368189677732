@import 'breakpoints';

$primary_font_family_string: 'Montserrat', sans-serif;

/** used and updated settings

h1: 700 18/24 
h2: 700 16/19
h3: 700 15/18
h4: 700 14/17
h5: 700 18/24 not used
h6: 700 18/24 not used

* all body is component p *
body1: 400 13/16
body2: 400 14/17
body3: 400 15/18
body4: 400 16/19 not used

* all subtitles is component h6 *
subtitle1 700 16/19 not used
subtitle2 700 15/18 not used
subtitle3 600 14/17

* classes to add font weight is *

 bold
 semi-bold
 medium
 normal

*/

:root {
  --base_font_size: 16px;
  --primary_font_family: #{$primary_font_family_string};
  --secondary_font_family: 'Montserrat', sans-serif;
  --table_font_size: 14px;
  --table_row_height: 56px;
  --h1_font_weight: 700;
  --h1_font_size: 18px;
  --h1_line_height: 24px;
  --h2_font_weight: 600;
  --h2_font_size: 16px;
  --h2_line_height: 19px;
  --h3_font_weight: 700;
  --h3_font_size: 15px;
  --h3_line_height: 18px;
  --h4_font_weight: 700;
  --h4_font_size: 14px;
  --h4_line_height: 17px;
  --h5_font_weight: 700;
  --h5_font_size: 18px;
  --h5_line_height: 24px;
  --h6_font_weight: 700;
  --h6_font_size: 18px;
  --h6_line_height: 24px;
  --subtitle1_font_weight: 700;
  --subtitle1_font_size: 16px;
  --subtitle1_line_height: 19px;
  --subtitle2_font_weight: 700;
  --subtitle2_font_size: 15px;
  --subtitle2_line_height: 18px;
  --subtitle3_font_weight: 600;
  --subtitle3_font_size: 14px;
  --subtitle3_line_height: 17px;
  --body1_font_family: var(--secondary_font_family);
  --body1_font_weight: 400;
  --body1_font_size: 13px;
  --body1_line_height: 16px;
  --body2_font_family: var(--secondary_font_family);
  --body2_font_weight: 400;
  --body2_font_size: 14px;
  --body2_line_height: 17px;
  --body3_font_family: var(--secondary_font_family);
  --body3_font_weight: 400;
  --body3_font_size: 15px;
  --body3_line_height: 18px;
  --body4_font_family: var(--secondary_font_family);
  --body4_font_weight: 400;
  --body4_font_size: 15px;
  --body4_line_height: 19px;
  --button_font_weight: 700;
  --button_font_size: 15px;
  --button_line_height: 18px;
}

@media (max-width: $md) {
  :root {
    // --h1_font_size: 18px;
    // --h1_line_height: 24px;
    // --h2_font_size: 16px;
    // --h2_line_height: 19px;
    // --h3_font_size: 16px;
    // --h3_line_height: 21px;
    // --h4_font_size: 16px;
    // --h4_line_height: 21px;
    // --h5_font_size: 16px;
    // --h5_line_height: 21px;
    // --h6_font_size: 16px;
    // --h6_line_height: 21px;
    // --subtitle1_font_weight: 600;
    // --subtitle1_font_size: 16px;
    // --subtitle1_line_height: 19px;
    // --subtitle2_font_size: 16px;
    // --subtitle2_line_height: 19px;
    // --subtitle3_font_size: 15px;
    // --subtitle3_line_height: 18px;
  }
}

$base_font_size: var(--base_font_size);
$primary_font_family: $primary_font_family_string;
$secondary_font_family: var(--secondary_font_family);
$table_font_size: var(--table_font_size);
$table_row_height: var(--table_row_height);
$h1_font_weight: var(--h1_font_weight);
$h1_font_size: var(--h1_font_size);
$h1_line_height: var(--h1_line_height);
$h2_font_weight: var(--h2_font_weight);
$h2_font_size: var(--h2_font_size);
$h2_line_height: var(--h2_line_height);
$h3_font_weight: var(--h3_font_weight);
$h3_font_size: var(--h3_font_size);
$h3_line_height: var(--h3_line_height);
$h4_font_weight: var(--h4_font_weight);
$h4_font_size: var(--h4_font_size);
$h4_line_height: var(--h4_line_height);
$h5_font_weight: var(--h5_font_weight);
$h5_font_size: var(--h5_font_size);
$h5_line_height: var(--h5_line_height);
$h6_font_weight: var(--h6_font_weight);
$h6_font_size: var(--h6_font_size);
$h6_line_height: var(--h6_line_height);
$subtitle1_font_weight: var(--subtitle1_font_weight);
$subtitle1_font_size: var(--subtitle1_font_size);
$subtitle1_line_height: var(--subtitle1_line_height);
$subtitle2_font_weight: var(--subtitle2_font_weight);
$subtitle2_font_size: var(--subtitle2_font_size);
$subtitle2_line_height: var(--subtitle2_line_height);
$subtitle3_font_weight: var(--subtitle3_font_weight);
$subtitle3_font_size: var(--subtitle3_font_size);
$subtitle3_line_height: var(--subtitle3_line_height);
$body1_font_family: var(--body1_font_family);
$body1_font_weight: var(--body1_font_weight);
$body1_font_size: var(--body1_font_size);
$body1_line_height: var(--body1_line_height);
$body2_font_family: var(--body2_font_family);
$body2_font_weight: var(--body2_font_weight);
$body2_font_size: var(--body2_font_size);
$body2_line_height: var(--body2_line_height);
$body3_font_family: var(--body3_font_family);
$body3_font_weight: var(--body3_font_weight);
$body3_font_size: var(--body3_font_size);
$body3_line_height: var(--body3_line_height);
$body4_font_family: var(--body4_font_family);
$body4_font_weight: var(--body4_font_weight);
$body4_font_size: var(--body4_font_size);
$body4_line_height: var(--body4_line_height);
$button_font_weight: var(--button_font_weight);
$button_font_size: var(--button_font_size);
$button_line_height: var(--button_line_height);

:export {
  base_font_size: $base_font_size;
  primary_font_family: $primary_font_family;
  primary_font_family_string: $primary_font_family_string;
  secondary_font_family: $secondary_font_family;
  table_font_size: $table_font_size;
  table_row_height: $table_row_height;
  h1_font_weight: $h1_font_weight;
  h1_font_size: $h1_font_size;
  h1_line_height: $h1_line_height;
  h2_font_weight: $h2_font_weight;
  h2_font_size: $h2_font_size;
  h2_line_height: $h2_line_height;
  h3_font_weight: $h3_font_weight;
  h3_font_size: $h3_font_size;
  h3_line_height: $h3_line_height;
  h4_font_weight: $h4_font_weight;
  h4_font_size: $h4_font_size;
  h4_line_height: $h4_line_height;
  h5_font_weight: $h5_font_weight;
  h5_font_size: $h5_font_size;
  h5_line_height: $h5_line_height;
  h6_font_weight: $h6_font_weight;
  h6_font_size: $h6_font_size;
  h6_line_height: $h6_line_height;
  subtitle1_font_weight: $subtitle1_font_weight;
  subtitle1_font_size: $subtitle1_font_size;
  subtitle1_line_height: $subtitle1_line_height;
  subtitle2_font_weight: $subtitle2_font_weight;
  subtitle2_font_size: $subtitle2_font_size;
  subtitle2_line_height: $subtitle2_line_height;
  subtitle3_font_weight: $subtitle3_font_weight;
  subtitle3_font_size: $subtitle3_font_size;
  subtitle3_line_height: $subtitle3_line_height;
  body1_font_family: $body1_font_family;
  body1_font_weight: $body1_font_weight;
  body1_font_size: $body1_font_size;
  body1_line_height: $body1_line_height;
  body2_font_family: $body2_font_family;
  body2_font_weight: $body2_font_weight;
  body2_font_size: $body2_font_size;
  body2_line_height: $body2_line_height;
  body3_font_family: $body3_font_family;
  body3_font_weight: $body3_font_weight;
  body3_font_size: $body3_font_size;
  body3_line_height: $body3_line_height;
  body4_font_family: $body4_font_family;
  body4_font_weight: $body4_font_weight;
  body4_font_size: $body4_font_size;
  body4_line_height: $body4_line_height;
  button_font_weight: $button_font_weight;
  button_font_size: $button_font_size;
  button_line_height: $button_line_height;
}
