@import '@infopulse-design-system/shared/theme/colors.scss';

$card-border: 1px solid $primary_light;

.persona-tv_card {
  max-width: 300px;
  box-shadow: none;
  border: $card-border;
  overflow: visible;

  &.open {
    z-index: 2;
  }

  .collapsed-content {
    position: absolute;
    top: 100%;
    left: -1px;
    width: 100%;
    background-color: $text_secondary;
    border-left: $card-border;
    border-right: $card-border;
    border-bottom: $card-border;
  }

  &-media {
    max-height: 200px;
    min-height: 169px;
    background-color: $primary_light;
    width: 100%;
    height: auto;
  }
  &-top-content {
    height: 119px;
    p {
      height: 100%;
      overflow: hidden;
      -webkit-line-clamp: 7;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
  }

  .MuiCardActions-root .expand-icon {
    transform: rotate(0deg);
    transition: transform 200ms;
    margin-left: auto;

    &.expanded {
      transform: rotate(180deg);
    }
  }

  .MuiCardHeader-title {
    color: $primary_main;
  }
}
