@import '@infopulse-design-system/shared/theme/colors.scss';

.search-result-item {
  overflow: hidden;
  position: relative;
  height: 140px;
  padding: 15px;
  border: 1px solid $primary_light;
  box-shadow: none;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 20px;
    width: 100%;
    background: linear-gradient(to bottom, transparent, white);
  }

  &:not(:first-of-type) {
    top: -1px;
  }
  &.active {
    background-color: $primary_light;
    &:after {
      background: linear-gradient(to bottom, transparent, $primary_light);
    }
  }

  .top-line {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &_chip {
      margin-right: 10px;
    }

    &_type-icon {
      margin: 0 3px 0 6px;
    }

    &_additional {
      display: flex;
      align-items: center;
      margin-left: auto;
      .ngo-chip {
        margin: 0 3px;
      }
      .user-tags-title {
        margin-right: 6px;
      }
    }
  }
  .program-info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 6px 0;
  }
  .program-name {
    font-weight: 700;
    color: $primary_main;
  }
  .author-info {
    span {
      font-weight: 600;
      color: $primary_main;
    }
  }
  .program-title {
    margin: 6px 0;
    min-height: 19.5px;
  }
}
