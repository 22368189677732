$accordion_box_shadow: 0 0 1px 0 rgb(162 159 159 / 20%),
  -1px 0 21px 0 rgb(162 159 159 / 14%), 0 0 3px 0 rgb(162 159 159 / 12%);
$box_shadow_main: 0 0 21px rgba(162, 159, 159, 0.25);
$calendar_box_shadow: 0 0 5px rgba(0, 0, 0, 0.25);
$tooltip_box_shadow: 0 0 10px rgba(0, 0, 0, 0.25);
$card_box_shadow: 0 0 3px 1px rgba(200, 200, 200, 0.25);
$card_raised_box_shadow: 2px 2px 22px 6px rgba(200, 200, 200, 0.25);
$dialog_box_shadow: 2px 2px 22px 6px rgba(200, 200, 200, 0.25);

:export {
  accordion_box_shadow: $accordion_box_shadow;
  box_shadow_main: $box_shadow_main;
  calendar_box_shadow: $calendar_box_shadow;
  tooltip_box_shadow: $tooltip_box_shadow;
  card_box_shadow: $card_box_shadow;
  card_raised_box_shadow: $card_raised_box_shadow;
  dialog_box_shadow: $dialog_box_shadow;
}
