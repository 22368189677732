@import '@infopulse-design-system/shared/theme/shadows.scss';
@import '@infopulse-design-system/shared/theme/colors.scss';

$quick-search-input-height: 35px;

.text-found {
  background: yellow;
  &-current {
    background: orange;
  }
}

.quick-search {
  display: flex;
  justify-content: space-between;
  width: 250px;
  min-width: 250px;
  height: $quick-search-input-height;
  background-color: $text_secondary;
  box-shadow: $box_shadow_main;
  border-radius: 26px;

  .ngo-text-field {
    margin: 0;
    padding: 0 5px;
    width: 60%;

    .MuiInput-root {
      height: $quick-search-input-height;
      padding: 5px;
      &:after,
      &::before {
        display: none;
      }
    }

    .MuiFormHelperText-root {
      bottom: $quick-search-input-height;
      position: absolute;
    }
  }

  .ngo-text {
    width: 60px;
    line-height: $quick-search-input-height;
    margin-right: 10px;
    text-align: center;
    border-right: 1px solid #e6e5ff;
  }

  .ngo-icon-button {
    margin: 0 3px;
  }

  &_navigation {
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
  }
}
