@import '@infopulse-design-system/shared/theme/colors.scss';

$video_min_width: 250px;
$dot_height: 6px;

:export {
  video_min_width: $video_min_width;
  dot_height: $dot_height;
}

.video {
  &-wrapper {
    max-width: 450px;
    min-width: $video_min_width;
    display: flex;
    flex-direction: column;

    video {
      max-height: 250px;
    }
  }

  &-periods {
    height: $dot_height;
    background-color: $primary_dark;
    position: relative;
    width: 100%;
  }

  &-period {
    position: absolute;
    padding: 0;
    width: $dot_height;
    height: $dot_height;
    background-color: #fbc400;
    border: none;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      transform: scale(2);
    }
    &.active {
      background-color: $error_main;
      z-index: 2;
    }
  }
}
