@import '@infopulse-design-system/shared/theme/colors.scss';

.profile-popover .MuiPaper-root {
  width: 200px;

  .ngo-list-item {
    padding: 0;
  }

  a,
  .logout,
  .login {
    display: block;
    padding: 7px 24px;
    color: $primary_dark;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    &.active {
      font-weight: 700;
    }
  }
}
