@import '@infopulse-design-system/shared/theme/colors.scss';
@import '@infopulse-design-system/shared/mixins/media.scss';

.admin-panel {
  padding: 16px;
  position: relative;

  .MuiTabPanel-root {
    padding: 0;
  }
}
