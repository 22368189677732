.basket-item {
  display: flex;
  align-items: flex-start;

  &:first-of-type {
    margin-top: 5px;
  }

  .ngo-checkbox {
    width: 50px;
  }
  .ngo-card {
    width: 100%;
  }
}
