@import '../../../constants/global.config.constants';

.#{$prfx}-select {
  &--react {
    width: 100%;

    label {
      transform: translate(0, -9px) scale(0.75);
      left: 0;
    }

    .MuiPaper-root.MuiMenu-paper {
      padding: 0;
    }

    .MuiInput-underline .MuiSelect-select {
      padding-left: 0;
      padding-right: 25px !important;
    }

    .MuiInput-root .MuiSelect-select {
      svg {
        display: none;
      }
    }

    .ngo-menu-item {
      padding: 5px 10px;
      min-height: 30px;
      font-size: 14px;
      line-height: 16px;
      text-wrap: wrap;
      svg {
        font-size: 1.5em;
        margin-right: 6px;
      }
    }
  }
}
