@import '@infopulse-design-system/shared/theme/colors.scss';

.filters-list-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 50.5px); // 50.5px is panel title
  padding: 0 12px;
}

.filters_top-line {
  position: relative;
}

.filters_action-btn {
  position: absolute;
  height: 36px;
  padding: 0;

  .button-content {
    margin-left: 50px;
    .MuiSvgIcon-root {
      color: $text_secondary;
    }
  }

  .active::after {
    content: '';
    position: absolute;
    top: -1px;
    right: -1px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: $error_main;
  }
}

.filters_top-line,
.page {
  & > .filters_action-btn {
    left: -60px;
  }
}

.filters_top-line > .filters_action-btn {
  top: -12px;
}

.page > .filters_action-btn {
  top: 13px;
}

.smart-filters .main-subtitle_result {
  padding-left: 40px;
}

.page .main-subtitle_filter {
  padding-left: 50px;
}
