// @import '@infopulse-design-system/shared/theme/typography.scss';
// @import '@infopulse-design-system/shared/theme/colors.scss';
@import '@infopulse-design-system/shared/mixins/index.scss';

.media-processing {
  position: relative;
  padding: 60px 15px 0 15px;

  .btn_go-back {
    position: absolute;
    top: 16px;
  }

  @include progress-bar;

  .preview_addition-menu {
    right: 10px;
  }

  .top-line {
    padding-top: 20px;
  }

  @media (max-width: 500px) {
    padding-top: 90px;
  }
}
