@import '@infopulse-design-system/shared/theme/colors.scss';

.select-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  .ngo-chip {
    margin: 3px;
    padding: 0 8px;
    span {
      font-weight: 500;
    }
  }

  &-title {
    display: inline-block;
  }

  &-popover {
    .MuiPaper-root {
      width: 245px;
      padding: 15px;
      box-sizing: border-box;
      overflow-x: auto;
    }
    .ngo-text-field {
      margin: 0 10px 0 0;
      padding: 0;

      .MuiInputBase-root {
        height: 35px;
        line-height: 35px;
      }
    }
  }

  &-add-btn {
    width: 35px;
    .MuiChip-label {
      font-size: 20px;
      line-height: 10px;
    }
  }

  &-items {
    max-height: 130px;
    overflow-y: scroll;
    margin-right: -7px;
    padding-right: 5px;
  }

  &-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 0;
    &:hover {
      background-color: $primary_light;
      cursor: pointer;
    }
    > .MuiTypography-root {
      font-weight: 500;
      font-size: 14px;
    }
    .Mui-checked + .MuiTypography-root {
      font-weight: 600;
    }
  }

  &-input {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    margin-bottom: 15px;

    .IpForm-element-error {
      position: absolute;
      bottom: -13px;
    }
  }
}
