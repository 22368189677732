@import '@infopulse-design-system/shared/theme/colors.scss';
@import '@infopulse-design-system/shared/theme/typography.scss';

.speaker-select-menu {
  #speaker-list {
    max-height: 160px;
    overflow-y: scroll;
    margin-bottom: 5px;
    max-width: 300px;
  }

  & > .MuiPopover-paper {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    min-width: 300px;
    min-height: 200px;
    position: absolute !important;
  }

  .ngo-input {
    margin: 10px 0 15px;
    width: 100%;
  }

  .ngo-radio-group {
    width: 100%;
    flex-wrap: nowrap;

    label {
      color: $text_primary;
      margin-bottom: 6px;
      margin-right: 5px;
      & > p {
        font-size: 13px;
      }
    }
  }

  .speaker-add {
    margin: 15px auto;
    width: 100px;
  }
  .speaker-nothing {
    margin: 15px;
    font: 400 15px/18px $primary_font_family;
  }
}
