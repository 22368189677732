.processing-form {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 360px;
  padding: 4px;

  .MuiFormControl-root {
    // margin: 15px 0 0 0;

    &:last-of-type {
      width: 100px;
      margin: 10px auto;
    }
  }
}
