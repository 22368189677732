.filter-checkbox {
  margin-bottom: 5px;
  label {
    display: block;
    margin: 5px 5px 5px 0;

    .MuiFormControlLabel-label {
      font-size: 13px;
    }
  }
}
