@import '@infopulse-design-system/shared/theme/colors.scss';
@import '@infopulse-design-system/shared/mixins/index.scss';

.dashboard {
  &-result {
    position: relative;
    width: calc(100vw - 280px); // 280 is filters
    padding: 25px 12px 0 12px;
    box-sizing: border-box;
    overflow-y: overlay;
  }

  &-chart-wrapper {
    width: 75%;
    height: 75vh;
    position: relative;
    @include progress-bar;
  }
}
