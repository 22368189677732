@import '@infopulse-design-system/shared/theme/colors.scss';
@import '@infopulse-design-system/shared/mixins/media.scss';

.media-processing,
.main-preview,
.details-preview {
  position: relative;
  height: 100%;

  h6 {
    margin: 5px 0;
  }

  .quick-search {
    position: absolute;
    top: 15px;
    right: 60px;
    width: 50%;
  }

  .btn_next-speaker {
    position: absolute;
    top: 16px;
    right: 5px;
  }

  .top-line {
    position: relative;
    padding-right: 30px;
  }

  .block-1 {
    display: flex;
    min-height: 40px;
  }

  .block-2 {
    overflow-x: auto;
    flex-grow: 2;
  }

  .transcription-list {
    padding-bottom: 100px;
  }
}

.main-preview {
  .telegram-nav {
    position: absolute;
    top: 24px;
    left: 80px;
  }

  &_program-name {
    margin-bottom: 5px;
  }

  &_tags-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
  }

  @media (max-width: 1300px) {
    .video-wrapper {
      width: calc(100% - 26px);
    }
  }
}

body:not(.smart-filters) .main-preview {
  @media (min-width: 1200px) and (max-width: 1300px) {
    h2 {
      margin-bottom: 42px;
    }
    .quick-search {
      top: 48px;
      right: 12px;
      width: calc(100% - 24px);
    }
  }
}

.media-processing,
.details-preview {
  .preview_addition-menu {
    flex-direction: row;
  }
  .top-line {
    padding-right: 125px;
  }

  // big
  @include tablet-landscape-up {
    .content-wrapper {
      display: flex;
    }
    .block-1 {
      flex-direction: column;
      min-width: 350px;
      max-width: 450px;
      overflow-x: hidden;
      overflow-y: scroll;
    }

    .block-2 {
      margin-left: 20px;
    }
    .media-info {
      margin: 10px 0 6px 0;
    }
  }

  @media (max-width: 500px) {
    .btn_go-back {
      margin-bottom: 48px;
    }
    .quick-search {
      top: 48px;
      right: 12px;
      width: calc(100% - 24px);
    }
  }
}
