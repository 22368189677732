@import '@infopulse-design-system/shared/theme/colors.scss';

.filter-search {
  &-input {
    width: 100%;
    margin-top: 0;
    margin-bottom: 15px;
  }

  &-checkbox {
  }

  &-icon {
    margin-right: -10px;
  }

  &-checkbox-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 200px;
    overflow-y: scroll;
  }

  &-checkbox-item {
    width: 100%;
    padding-bottom: 10px;
    .MuiFormControlLabel-label {
      margin-right: 8px;
      font-size: 13px;
    }
  }
}
