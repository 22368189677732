@import '@infopulse-design-system/shared/theme/colors.scss';

.filter-period {
  padding-bottom: 15px;

  &-annotator {
    max-width: 260px;
    margin-right: 20px;

    .filter-period-inputs > .MuiFormControl-root {
      margin-right: 5px;
    }
  }

  .MuiCalendarPicker-root {
    width: 278px;
  }

  .PrivatePickersYear-root {
    flex-basis: 31%;
  }

  .MuiMonthPicker-root {
    width: 260px;
  }

  .IpDateTimePicker {
    min-width: 123px;
    width: 123px;
    margin-top: 10px;

    label {
      max-width: 123px;

      &.MuiFormLabel-root.MuiFormLabel-root {
        transform: translate(0, -12px) scale(0.75);
        &[data-shrink='false'] {
          transform: translate(5px, 0) scale(1);
        }
      }
    }

    .MuiIconButton-edgeEnd {
      padding: 0;
    }

    & > .MuiInputBase-root {
      padding: 0;
      display: flex;
      & > .MuiInputAdornment-root {
        order: -1;
        width: 40px;
      }
    }
  }

  &-inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  &-btns button {
    width: 48px;
    height: 25px;
    min-height: 25px;
    min-width: 48px;
    font-size: 13px;
    font-weight: 600;
    line-height: 17px;
    margin: 10px 10px 0 0;

    &.MuiButton-outlined {
      &,
      &:hover {
        color: $primary_dark;
      }
      .hover-animation:before {
        background-color: transparent;
      }
    }
  }
}

.markedDays {
  background-color: $primary_light;
}
