@import '@infopulse-design-system/shared/theme/colors.scss';

.filter-range {
  display: flex;
  flex-direction: column;

  .ngo-text-field {
    min-width: 50px;
  }

  &-inputs {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 10px;
    label {
      top: -6px;
      left: 0;
      max-width: 50px;
    }
  }

  &-divider {
    width: 10px;
    height: 2px;
    margin: 8px;
    background-color: $primary_dark;
  }
}
