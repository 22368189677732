.basket-actions-panel {
  margin-bottom: 20px;

  &-checkbox {
    .MuiFormControlLabel-label {
      font-size: 14px;
    }
  }
  &-btn {
    margin-left: 25px;
    font-weight: 400;
  }
}