@import './colors.scss';

$scrollbar_width: 5px;
$scrollbar_height: 5px;
$scrollbar_handle_border_radius: 7px;

/* width */
body ::-webkit-scrollbar {
  width: $scrollbar_width;
  height: $scrollbar_height;
}

/* Track */
body ::-webkit-scrollbar-track {
  background: $background;
}

/* Handle */
body ::-webkit-scrollbar-thumb {
  background: $primary_main;
  border-radius: $scrollbar_handle_border_radius;
}

/* Handle on hover */
body ::-webkit-scrollbar-thumb:hover {
  background: $primary_dark;
}
