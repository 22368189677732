@import '@infopulse-design-system/shared/theme/colors.scss';

.filters-list {
  padding-bottom: 50px;
  .ngo-list-item {
    &.Mui-disabled {
      .IpList-endIcon {
        transform: rotate(0deg);
      }
      & + li {
        display: none;
      }
    }
    &:not(.IpListItem-disablePadding) {
      padding: 5px 5px 5px 0;
      background-color: transparent;

      &:nth-child(2n + 1) {
        border-top: 1px solid #e6e5ff;
      }

      &:nth-child(2n) {
        & > .MuiTouchRipple-root {
          display: none;
        }
      }
    }
  }

  .ngo-list-item-text > .MuiTypography-root {
    color: $primary_main;
  }

  .ngo-collapse + .MuiTouchRipple-root {
    display: none;
  }
}
