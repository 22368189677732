.btn_next-speaker {
  .button-content {
    display: flex;
    align-items: center;
    padding: 5px;
    svg:last-of-type {
      margin-left: -7px;
    }
  }
}
