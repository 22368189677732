@import '@infopulse-design-system/shared/theme/colors.scss';
@import '@infopulse-design-system/shared/mixins/media.scss';

.terms-of-use {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  margin: 0 auto;
  padding: 24px 16px 32px 16px;
  height: calc(100vh - 120px);
  overflow-x: scroll;

  h1 {
    font-size: 24px;
    margin: 24px;
  }
}
