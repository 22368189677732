@import '@infopulse-design-system/shared/theme/colors.scss';

.filter-soc {
  margin-bottom: 10px;

  .ngo-radio-group label .MuiTypography-root {
    font-size: 13px;
    color: $text_primary;
  }

  .search-models-label {
    padding: 5px 18px;
  }
}
