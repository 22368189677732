@import '@infopulse-design-system/shared/theme/colors.scss';

.query-type-switcher {
  display: flex;
  margin-top: 5px;

  .ngo-text {
    cursor: pointer;
    width: 59px;
  }
  .ngo-switch {
    margin: 0 5px;
  }

  &.disabled {
    pointer-events: none;
    p {
      color: $grey_600;
    }
  }
}
