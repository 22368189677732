@import '@infopulse-design-system/shared/mixins/media.scss';

@mixin text-ellipsis {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin progress-bar {
  .ngo-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(245, 244, 255, 0.5);
    text-align: center;
    z-index: 10;
  }
}
