.SnackbarContainer-root {
  & > .MuiCollapse-root > .MuiCollapse-wrapper.MuiCollapse-wrapper {
    padding: 0;

    & .SnackbarContent-root {
      background-color: transparent;
      box-shadow: none;
      min-width: 0;
      padding: 0;

      & > .SnackbarItem-message {
        padding: 5px 0;
        min-width: 250px;
        & > * {
          width: 100%;
        }
      }
    }
  }
}
