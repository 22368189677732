@import '@infopulse-design-system/shared/theme/colors.scss';

.persona-preview {
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 28px;
  height: calc(100% - 60px);

  &_top {
    position: relative;
    padding-right: 50px;

    .persona_addition-menu {
      position: absolute;
    }
  }

  &_title {
    display: inline-block;
    margin-right: 10px;

    button {
      margin-left: 10px;
    }
  }

  .persona-info {
    margin: 12px 0;

    &_avatar {
      margin: 0 20px 20px 0;
    }

    &_left,
    &_right {
      min-width: 200px;
    }

    &_left {
      margin-right: 10px;
    }

    &_sanctions {
      display: flex;
      flex-direction: column;
    }
  }
}

.persona-info_title {
  margin-bottom: 21px;
}

.persona-info {
  display: flex;
  flex-wrap: wrap;

  &_avatar {
    margin-right: 12px;
  }

  li {
    margin-bottom: 10px;

    span {
      margin-right: 6px;
    }
  }
}

.persona-media {
  margin-top: 21px;

  svg {
    fill: $primary_dark;
  }

  a {
    margin-right: 8px;
  }

  a.disabled {
    svg,
    path {
      fill: $grey_600;
    }
  }

  &_list {
    display: inline-flex;
    align-items: center;
  }

  &_title {
    margin: 0 10px 10px 0;
  }
}

.persona-tv {
  & > div {
    display: flex;
    flex-wrap: wrap;
  }
}
