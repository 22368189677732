@import '@infopulse-design-system/shared/theme/colors.scss';
@import '@infopulse-design-system/shared/mixins/media.scss';

.main {
  &-subtitle {
    margin-bottom: 16px;
  }

  &-result {
    position: relative;
    width: 39%;
    min-width: 524px;

    @include tablet-landscape-down {
      width: 100%;
      min-width: 0;
    }

    &-header {
      display: flex;
      justify-content: space-between;
    }

    &-btns {
      position: relative;
      top: 10px;
      button {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        margin-right: 20px;
        border-radius: 2px 2px 0 0;
        &[disabled] {
          background-color: transparent;
        }
      }

      @include tablet-portrait-down {
        margin-right: 0;
        button {
          margin-right: 6px;
        }
      }
    }

    &-btn-active {
      font-weight: 600;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background-color: $primary_dark;
        bottom: -3px;
      }
    }
  }
}
